"use strict";
import "@babel/polyfill";
import "bootstrap";
import "../../sass/common/index.scss";
import "./icons";

export default (function () {

    $(function () {
        initPage();
        menuMobile();  

        window.onload = () => {
            cookies();
        };
    });

    function initPage() {
        const mainHeader = document.querySelector(".main-header");
        if (mainHeader) {
            if (window.scrollY > 50) {
                mainHeader.classList.add("main-header--primary");
            } else {
                mainHeader.classList.remove("main-header--primary");
            }

            window.addEventListener("scroll", () => {
                if (window.scrollY > 50) {
                    mainHeader.classList.add("main-header--primary");
                } else {
                    mainHeader.classList.remove("main-header--primary");
                }
            });

            const mainHero = document.querySelector(".main-hero");
            if (!mainHero) {
                mainHeader.classList.add("main-header--no-hero");
            }
        }
    }

    function menuMobile() {

        const menuButton = document.querySelector(".jsMenuBtn");
        const menuMobile = document.querySelector(".jsMenuMobile");

        if (menuButton && menuMobile) {
            menuButton.addEventListener("click", () => {
                menuMobile.classList.toggle("active");
                document.body.classList.toggle("overflow-hidden");

                if (menuMobile.classList.contains("active")) {
                    menuMobile.previousElementSibling.classList.add("active");
                } else {
                    menuMobile.previousElementSibling.classList.remove("active");
                }
            });
        }
    }

    var setCookie;
    global.setCookie = function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    };

    var getCookie;
    global.getCookie = function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return ca;
    };

    global.checkCookie = function () {
        var user = getCookie("username");
        if (user != "") {
            alert("Welcome again " + user);
        } else {
            user = prompt("Please enter your name:", "");
            if (user != "" && user != null) {
                setCookie("username", user, 365);
            }
        }
    };

    global.eraseCookie = function (name) {
        document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    };

    function cookies() {
        const cookieBtn = document.getElementById("cookieBtn");
        const cookieContent = document.getElementById("cookiePolicy");

        cookieBtn.addEventListener("click", (e) => {
            e.preventDefault();
            global.setCookie("privacy", 1, 30);
            cookieContent.classList.add("d-none");
        });

        if (global.getCookie("privacy") != 1) {
            cookieContent.classList.remove("d-none");
        }
    }
   
}());