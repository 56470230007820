/* Common icons */
import "../../images/icons/logo.svg";
import "../../images/icons/close.svg";
import "../../images/icons/file-text.svg";
import "../../images/icons/clock.svg";
import "../../images/icons/book-open.svg";
import "../../images/icons/book.svg";
import "../../images/icons/pen-tool.svg";
import "../../images/icons/menu.svg";
import "../../images/icons/mail.svg";
import "../../images/icons/map.svg";
import "../../images/icons/map-pin.svg";
import "../../images/icons/headphones.svg";
import "../../images/icons/coffee.svg";
import "../../images/icons/briefcase.svg";
import "../../images/icons/star.svg";
import "../../images/icons/check-circle.svg";
import "../../images/icons/phone.svg";
import "../../images/icons/chevron.svg";
import "../../images/icons/certificate.svg";
import "../../images/icons/cellphone.svg";
import "../../images/icons/teaching.svg";
import "../../images/icons/method.svg";

/* Social icons */
import "../../images/icons/social-facebook.svg";
import "../../images/icons/social-instagram.svg";
import "../../images/icons/social-linkedin.svg";
import "../../images/icons/social-twitter.svg";
import "../../images/icons/social-whatsapp.svg";
import "../../images/icons/social-youtube.svg";
